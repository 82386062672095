import { smFormValidationInstall } from 'form-helpers/form-validation';
import { smRemoteFormInstall } from 'form-helpers/remote-form';
import { smPasswordVisibilityToggle } from 'form-helpers/password-visibility-toggle';

import './new.scss';

document.addEventListener('DOMContentLoaded', function() {
  var $form = document.getElementById('new_app_authenticate');

  smFormValidationInstall($form);
  smRemoteFormInstall($form);

  smPasswordVisibilityToggle('#app_authenticate_password');

  // redirect the browser on successful login
  $form.addEventListener('ajax:success', function(event) {
    var response = event.detail[0];
    location.href = window.location.origin + response.return_to;
  });
});