/**
 * DOM functions for simple browser native form validations
 *
 * on install, attaches to all inputs not marked novalidate, and does the following:
 *
 * - manages a 'visited' class on inputs that have been focused at least once
 * - manages 'error lists' which are uls marked `ul[data-show-errors=$fieldname]`
 * - programatically kicks off browser native validation on input blur and keypress
 */

export function smFormValidationInstall(form) {
  var formErrorMessages = {
    badInput:        'Invalid format',
    patternMismatch: 'Invalid format',
    typeMismatch:    'Invalid format',
    rangeOverflow:   'Value is too large',
    rangeUnderflow:  'Value is too small',
    tooLong:         'Value is too long',
    tooShort:        'Value is too short',
    valueMissing:    'Required'
  };

  function executeInstall() {
    var inputs = form.querySelectorAll('input:not([novalidate]), select:not([novalidate]), textarea:not([novalidate])');
    for (var j = 0; j < inputs.length; j++) {
      inputs[j].addEventListener('blur',   markVisited);
      inputs[j].addEventListener('blur',    checkValidity);
      inputs[j].addEventListener('input',   checkValidity);
      inputs[j].addEventListener('invalid', fieldInvalid);
    }

    var confirmInputs = form.querySelectorAll('input[data-validate-matches]');
    for (var j = 0; j < confirmInputs.length; j++) {
      installMatchValidator(confirmInputs[j]);
    }
  }

  function installMatchValidator(checkInput) {
    var matchId    = form.dataset['formPrefix'] + checkInput.dataset['validateMatches'];
    var matchInput = form.querySelector('input#' + matchId);
    if (!matchInput) {
      console.log('couldn\'t find input to match for custom validation!, bailing!', checkInput);
      return;
    }

    var validateMatches = function() {
      if (checkInput.value === matchInput.value) {
        checkInput.setCustomValidity('');
      } else {
        checkInput.setCustomValidity(checkInput.dataset['validateMatchesMessage'] || 'Values must match');
      }
      handleErrorsVisibility(checkInput);
    };

    checkInput.addEventListener('input', validateMatches);
    matchInput.addEventListener('input', validateMatches);
  }

  /** get error messages based on the field's validity state */
  function getErrorMessage(field) {
    if (field.validity && !field.validity.valid) {
      for (var key in field.validity) {
        if (key === 'customError' && field.validity[key]) {
          return field.validationMessage;
        }

        if (field.validity[key] && formErrorMessages[key]) {
          return formErrorMessages[key];
        }
      }

      return 'Invalid';
    }

    return null;
  }

  /* find the error list in the form which holds errors for field */
  function getErrorList(field) {
    var errorname = field.id.replace(form.dataset['formPrefix'], '');
    if (!errorname) {
      return null;
    }

    return form.querySelector('ul[data-show-errors=' + errorname + ']');
  }

  /* mark the event's target has having been visited */
  function markVisited(event) {
    event.target.classList.add('visited');
  }

  /* kick off validation checks:
     if valid   - clear the error list.
     if invalid - triggers an `invalid` event (so we don't handle it here)
  */
  function checkValidity(event) {
    if (event.target.classList.contains('visited') && event.target.checkValidity()) {
      var $errorlist = getErrorList(event.target);
      while($errorlist && $errorlist.firstChild) {
        $errorlist.removeChild($errorlist.firstChild);
      }
    }
  }

  /* handle a field-level `invalid` event */
  function fieldInvalid(event) {
    handleErrorsVisibility(event.target);
  }


  /* handle errors visibility
     - remove extant errors (leaving one to reuse the dom node)
     - if the field is in a valid state, remove the remaining node
     - if the field is in an invalid state, use the remaining node (or create a new one) to show the error
  */
  function handleErrorsVisibility(field) {
    var $errorlist = getErrorList(field);
    if (!$errorlist) {
      return;
    }

    // clear all but one child
    // prevent flicker: we'll reuse that one below, or remove it if it should actually be gone
    while($errorlist.firstChild != $errorlist.lastChild) {
      $errorlist.removeChild($errorlist.firstChild);
    }

    // there's an error to show
    if (field.validity.valid === false && field.classList.contains('visited')) {

      // there's a child to re-use
      if ($errorlist.firstChild) {
        $errorlist.firstChild.innerText = getErrorMessage(field);
      }

      // there isn't a child to reuse
      else {
        var $error = document.createElement('li');
        $error.innerText = getErrorMessage(field);
        $errorlist.appendChild($error);
      }
    }

    // there's no error to show, so we should remove even the last child
    else {
      if($errorlist.firstChild) {
        $errorlist.removeChild($errorlist.firstChild);
      }
    }
  }

  executeInstall();
}
