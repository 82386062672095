/**
 * Remote form management, extending form validation
 *
 * on install, attaches rails remote form event handlers
 *
 * - disables the form container during submission
 * - displays form errors on ajax error
 */

export function smRemoteFormInstall($form) {
  var $container = $form.querySelector('fieldset.form-container');

  // disable the form on ajax out
  $form.addEventListener('ajax:before', function(event) {
    $container.querySelector('button[type=submit]').setAttribute('disabled', '');
  });

  // re-enable the form on ajax complete
  $form.addEventListener('ajax:complete', function(event) {
    if (!$form.dataset['remoteStayDisabled']) {
      $container.querySelector('button[type=submit]').removeAttribute('disabled');
    }
  });

  // replace errors on the form on error
  $form.addEventListener('ajax:error', function(event) {
    var response = event.detail[0];
    var status   = event.detail[1];
    var xhr      = event.detail[2];

    var $errorlist, key, i;

    clearFormErrors();

    if (xhr.status >= 400 && xhr.status < 500) {
      for(key in response.errors) {
        $errorlist = $form.querySelector('ul[data-show-errors=' + key + ']');
        if (!$errorlist) { continue; }

        for(i = 0; i < response.errors[key].length; i++) {
          appendFormError($errorlist, response.errors[key][i]);
        }
      }
    }
    else {
      $errorlist = $form.querySelector('ul[data-show-errors=base]');
      appendFormError($errorlist, 'Something went wrong, please try again in a few moments.');
    }
  });

  function clearFormErrors() {
    var $errorlists, i;

    $errorlists = $form.querySelectorAll('ul[data-show-errors]');
    for(i = 0; i < $errorlists.length; i++) {
      $errorlists[i].innerText = '';
    }
  }

  function appendFormError($errorlist, error) {
    var $error = document.createElement('li');
    $error.innerText = error;
    $errorlist.appendChild($error);
  }
}
