import $ from 'jquery';
import './password-visibility-toggle.scss';

/**
 * smPasswordVisibilityToggle adds and initializes a password visibility toggle inside password input fields.
 * The trigger text is 'Show/Hide'.
 * 
 * @param {string} selector - The selector for the target input to receive the visibility toggle element. 
 * e.g. '#app_authenticate_password'
 */
export function smPasswordVisibilityToggle(selector) {
  var $input = $(selector);
  var showHideButtonClass = 'show-hide-button-' + selector.replace(/[#.]/g, '');
  var showHideButtonHtml = `<button type="button"
    class="${showHideButtonClass} iris-button iris-button--tertiary"
    aria-label="Toggle password visibility">Show</button>`;

  // call the init function
  executeInit();

  /** 
   * Initialization function.
   * @returns {void}
   */
  function executeInit() {
    insertShowHideButton();
    updateFormField();

    initClickEventListener();
    initFormFieldEventListeners();
  }

  /**
   * Initializes `click` event listener on the 'Show/Hide' button.
   *
   * @returns {void}
   */
  function initClickEventListener() {
    $('.' + showHideButtonClass).on('click', function($event) {
      // prevent form fields from being marked as touched when toggling password visibility
      $event.preventDefault();

      // toggle password visibility by changing the input type
      const updatedInputType = $input.attr('type') === 'password' ? 'text' : 'password';
      $input.attr('type', updatedInputType);

      // toggle the 'Show/Hide' button text
      this.innerText = this.innerText == 'Show' ? 'Hide' : 'Show';
    });
  }

  function initFormFieldEventListeners() {
    $input.on('blur',   markFieldVisited);
    $input.on('input',   checkFieldValidity);
    $input.on('invalid', checkFieldValidity);
  }

  function markFieldVisited(event) {
    $input.parent().addClass('visited');
  }

  function checkFieldValidity(event) {
     $input.parent().toggleClass('invalid', $input.is(":invalid"));
  }

  /**
   * Inserts the toggle trigger into the input.
   *
   * @returns {void}
   */
  function insertShowHideButton() {
    $input.after(showHideButtonHtml);
  }

  /**
   * Adds the 'show-hide-field' class to encasing element
   * (only updates if parent is 'form-field-content' element).
   *
   * @returns {void}
   */
   function updateFormField() {
     if($input.parent().hasClass('form-field-content')) {
       $input.parent().addClass('show-hide-field');
     }
  }
}